<template>
  <div class="up-preview">
    <router-link
      class="up-preview__back-btn mb el-button el-button--primary"
      :to="lectureData.fromPage"
    >
      Вернуться к редактированию
    </router-link>
    <h1 class="up-main-title">Предпросмотр лекции: {{ lectureData.name }}</h1>
    <Lecture :lecture-data="lectureData" />
    <router-link
      class="up-preview__back-btn mt el-button el-button--primary"
      :to="lectureData.fromPage"
    >
      Вернуться к редактированию
    </router-link>
  </div>
</template>

<script>
import Lecture from "@/components/listener/course/Lecture";

import { mapActions } from "vuex";

export default {
  name: "LecturePreview",
  components: { Lecture },
  computed: {
    lectureData() {
      return JSON.parse(localStorage.getItem("lecture-preview-data"));
    },
  },
  methods: {
    ...mapActions(["setNavigationText"]),
  },
  created() {
    this.setNavigationText("Предпросмотр лекции");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/methodist/courses/preview.scss";
</style>